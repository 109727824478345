import React, { Component } from 'react';

import "./NavBar.css";

class NavBarPlain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
        };
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll(event) {
        if (window.scrollY > 20) {
            if (!this.refs.navbar.className.includes("navbar-scrolled")) {
                this.refs.navbar.className += " navbar-scrolled";
            }
        } else {
            this.refs.navbar.className = this.refs.navbar.className.replace(/ navbar-scrolled/g, "");
        }
    }
    render() {
        const brand = this.props.brand || "Dockington";
        return (
            <nav ref="navbar" className="navbar navbar-expand-md navbar-light fixed-top">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <span className="navbar-brand lobster">{brand}</span>
                    </div>
                </div>
            </nav>
        );
    }
}

export default NavBarPlain;
