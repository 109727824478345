import React from "react"

import Layout from "../components/Layout"
import SignUpPage from "../views/SignUpPage"

const Signup = ({ location, pageContext: { locale } }) => (
    <Layout location={location} locale={locale}>
        <SignUpPage />
    </Layout>
);

export default Signup;
