import React from "react";

const JumbotronFluid = function (props) {
    let style = {}
    if (props.color) {
        style.backgroundColor = props.color;
    }
    let className = "jumbotron jumbotron-fluid";
    if (props.className) {
        className += " " + props.className;
    }
    return (
        <div className={className} style={style}>
            <div className={props.containerClassName || "container-fluid"}>
                {props.children}
            </div>
        </div>
    );
};

export default JumbotronFluid;
