import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withTranslate } from "../../components/translate";
import Jumbotron from '../../components/common/Jumbotron';
import JumbotronFluid from '../../components/common/JumbotronFluid';
import Dockington from "../../components/common/Dockington";
import Row from "../../components/common/Row";
import Col from "../../components/common/Col";
import NavBarPlain from "../../components/common/NavBarPlain";
import ScrollToTopOnMount from "../../components/common/ScrollToTopOnMount";
import SEO from "../../components/SEO";

import SignUpForm from "./SignUpForm";

import "./SignUpPage.css";

const SignUpPage = withTranslate(function ({ translate }) {
    return (
        <div className="signuppage">
            <SEO
                title={translate("signup.head.title")}
                description={translate("signup.head.description")}
                path="/signup"
            />
            <ScrollToTopOnMount />
            <NavBarPlain brand="Dockington Test Drive"/>
            <header className="container pt-1 mt-4" style={{ textAlign: "center" }}>
                <div className="tagline mb-3">
                    <div className="intro mt-3">
                        <h1>
                            {translate("signup.tagline.title")}
                        </h1>
                        <p className="h2-sub">
                            {translate("signup.tagline.subtitle").map((part) => (part.dockington ? <Dockington /> : part))}
                        </p>
                    </div>
                </div>
            </header>
            <JumbotronFluid color="#fafafa" className="pt-0 pb-5 mb-0" containerClassName="px-0">
                <SignUpForm translate={translate} />
            </JumbotronFluid>
            <Jumbotron color="#fff">
                <section>
                    <Row justify="center">
                        <Col className="test-drive-points">
                            <p className="points-title">{translate("signup.details.title").map((part) => (part.dockington ? <span className="lobster">Dockington Test Drive</span> : part))}</p>
                            <ul className="points px-1 px-md-0">
                                {translate("signup.details.points").map((point, i) => (
                                    <li className="item" key={i}>
                                        <div className={point.can ? "good" : "bad"}>
                                            <FontAwesomeIcon icon={point.can ? "check" : "times"} />
                                        </div>
                                        <div>
                                            <p>{point.point}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </section>
            </Jumbotron>
        </div>
    )
});

export default SignUpPage;
